.testimonials .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}
  
.testimonials .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 567px;
    height: 382px;
    position: relative;
}

@media (max-width: 767px) {
	.testimonials .swiper-slide {
        width: 250px;
        height: 166px;
      }
}