/* @import url('./assets/images/arrow-right.svg'); */

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: none;
    font-size: var(--swiper-navigation-size);
    text-transform: none!important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: url('./images/arrow-right.svg');
    transform: scale(.8);
    margin-right: 24px;

}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: url('./images/arrow-left.svg');
    transform: scale(.8);
    margin-left: 24px;
}

@media (max-width: 767px) {
	.swiper-button-next, .swiper-rtl .swiper-button-prev {
        margin-top: 50% !important;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        margin-top: 50% !important;
    }
}

@media (max-width: 1024px) {
	.swiper-button-next, .swiper-rtl .swiper-button-prev {
        margin-top: 20%;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        margin-top: 20%;;
    }
}