@tailwind base;
@tailwind components;
@tailwind utilities;


.custom-container{
    max-width: 1920px;
    padding-left: 253px;
    padding-right: 253px;
    margin: 0px auto;
}

@media (max-width: 1919px) {
    .custom-container{
        max-width: 1920px;
        width: 100%;
        padding-left: 12%;
        padding-right: 12%;
        margin: 0px auto;
    }
}

@media (max-width: 1536px) {
    .custom-container{
        max-width: 1920px;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin: 0px auto;
    }
}


@media (max-width: 1280px) {
    .custom-container{
        width: 100%;
        padding-left: 7%;
        padding-right: 7%;
        margin: 0px auto;
    }
}

@media (max-width: 1024px) {
    .custom-container{
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin: 0px auto;
    }
}

